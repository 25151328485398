import React from 'react'
import styled from 'styled-components'

import { useAuth0 } from 'react-auth0-wrapper'

import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import PageLoader from 'components/PageLoader'
import TextLink from 'components/TextLink'

import messages from './messages'

const ContentContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  text-align: center;
`

const ClickableImage = styled.img`
  cursor: pointer;
`

const MessageContainer = styled.h3`
  margin-top: 10px;
  margin-bottom: 10px;
`

const AccountContainer = styled.h4`
  color: #666666; /* make it greyer so it looks disabled */
  margin: 0;
  margin-bottom: 15px;
`

export default function DisabledUserPage() {
  const { user, logout } = useAuth0()

  if (!user) {
    return <PageLoader />
  }
  return (
    <ContentContainer>
      <ClickableImage
        onClick={logout}
        style={{ height: '58px', width: 'auto', marginTop: '10px' }}
        src="https://sonrai-logos.s3.amazonaws.com/productLogo.png"
      />
      <MessageContainer>
        <DynamicFormattedMessage {...messages.disabledUserMessage} />
      </MessageContainer>
      <AccountContainer>{(user || {}).name}</AccountContainer>

      <TextLink
        color="primary"
        onClick={() => {
          logout()
          window.open('mailto:support@sonraisecurity.com')
        }}
      >
        <DynamicFormattedMessage {...messages.contactSupportMessage} />
      </TextLink>
    </ContentContainer>
  )
}
