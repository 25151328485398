import { defineMessages } from 'react-intl'

export default defineMessages({
  disabledUserMessage: {
    id: 'app.containers.DisabledUserPage.disabledUserMessage',
    defaultMessage: 'This account is disabled',
  },
  contactSupportMessage: {
    id: 'app.containers.DisabledUserPage.contactSupportMessage',
    defaultMessage: 'Click here to contact support',
  },
})
